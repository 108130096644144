import { Component, OnDestroy, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { EchargerService } from 'src/app/services/echarger.service'
import { ZoneService } from 'src/app/services/zone.service'
import { map, takeWhile } from 'rxjs/operators'
import { TransactionService } from 'src/app/services/transaction.service'
import { Transaction } from 'src/app/classes/transaction.class'
import { UtilityService } from 'src/app/services/utility.service'
import { MessageEloaded } from 'src/app/enums/echarger-message.enum'
import { DateFormat } from 'src/app/enums/date-format.enum'
import { Zone } from 'src/app/classes/zone.class'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'

@Component({
  selector: 'app-virtual-echarger',
  templateUrl: './virtual-echarger.component.html',
  styleUrls: ['./virtual-echarger.component.scss']
})
export class VirtualEchargerComponent
  extends FormCanDeactivate
  implements OnDestroy {
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  private subscriptions = new Array<Subscription>()
  private logSubscription: Subscription

  public loading = false
  public alert: Alert

  public chargerStati = [
    `Handshaking`,
    `Preparing`,
    `Charging`,
    `Stopping`,
    `ChargeSuspended`,
    `Finished`,
    `Waiting`
  ]

  public protocolsSupported = ['109']
  public socket: number
  public connected = false
  public parks = new Array<{ parkName: string, zones: Zone[] }>()
  public logs: string
  public showEchargerCodeWarning = false

  // buffered transaction to view one for each gun (4 guns)
  public transactionsCache = new Array<Transaction>(4)
  public transactionsView = new Array<Transaction>()

  // retrieved transactions
  public transactions = new Array<Transaction>(4)
  public echargerId: string

  public firstTransactions = new Array<string>()

  public Message = MessageEloaded
  public DateFormat = DateFormat

  constructor (
    private _translate: TranslateService,
    private _echarger: EchargerService,
    private _zone: ZoneService,
    private _transaction: TransactionService,
    public _utility: UtilityService
  ) {
    super()
    this.init()
  }

  public logsForm: FormGroup = new FormGroup({
    filter: new FormControl(''),
    tail: new FormControl(40,
      [Validators.required, Validators.min(1), Validators.max(1000)]
    )
  })

  public connectionForm: FormGroup = new FormGroup({
    echargerCode: new FormControl('',
      [Validators.required, Validators.pattern('^[0-9a-zA-Z]*$')]
    ),
    zone: new FormControl('', Validators.required),
    prechargeLimit: new FormControl(undefined,
      [Validators.min(1), Validators.max(120)]
    ),
  })

  // for input validation only
  public request44RegistrationForm: FormGroup = new FormGroup({
    protocol: new FormControl('109', Validators.required)
  })

  public request42PrechargeForm: FormGroup = new FormGroup({
    gunNr: new FormControl(1,
      [Validators.required, Validators.min(1), Validators.max(4)]
    ),
    evccid: new FormControl('1234567890AB',
      [Validators.required, Validators.pattern('^[0-9a-fA-F]{12}$')]
    ),
    soc: new FormControl(33,
      [Validators.required, Validators.min(0), Validators.max(100)]
    ),
  })

  public response41ChargeInfoForm: FormGroup = new FormGroup({
    gunNr: new FormControl(1,
      [Validators.required, Validators.min(1), Validators.max(4)]
    ),
    evccid: new FormControl('1234567890AB',
      [Validators.required, Validators.pattern('^[0-9a-fA-F]{12}$')]
    ),
    soc: new FormControl(33,
      [Validators.required, Validators.min(0), Validators.max(100)]
    ),
    chargeStatus: new FormControl('', Validators.required),
    presentCurrent: new FormControl(10,
      [Validators.required, Validators.min(0), Validators.max(65535)]
    ),
    presentVoltage: new FormControl(220,
      [Validators.required, Validators.min(0), Validators.max(65535)]
    ),
    startValueAmmeter: new FormControl(0.0,
      [Validators.required, Validators.min(0.0), Validators.max(429496729.5)]
    ),
    realValueAmmeter: new FormControl(5.3,
      [Validators.required, Validators.min(0.0), Validators.max(429496729.5)]
    ),
    chargeTime: new FormControl(5,
      [Validators.required, Validators.min(0), Validators.max(65535)]
    ),
  })

  public async init () {
    this.loading = true

    // -- fill zones in drop-down
    const response = await this._zone.searchZone().toPromise()
    if (response.invalid) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.INVALID`)} ${response.message}`
      )

      this.loading = false
      return
    }

    const zonesAvailable = response.data
    const parks =
      new Set<string>(zonesAvailable.map(({ parkName }) => parkName))

    parks.forEach(park => {
      const zones = zonesAvailable.filter(({ parkName }) => parkName === park)
      this.parks.push({ parkName: park, zones })
    })

    const selZone = zonesAvailable.find(({ name }) =>
      name.toUpperCase().includes('VIRTUAL TESTING')
    )
    if (selZone) {
      this.connectionForm.patchValue({ zone: selZone._id })
    }

    this.connectionForm.patchValue({ protocol: `109` })
    this.response41ChargeInfoForm.patchValue({ chargeStatus: `Charging` })
    this.response41ChargeInfoForm.markAsUntouched()

    this.request44RegistrationForm.disable()
    this.request42PrechargeForm.disable()
    this.response41ChargeInfoForm.disable()

    this.loading = false
  }

  ngOnDestroy () {
    if (this.connected) {
      this.disconnectServer()
    }
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  preventPageLeave (event) {
    event.preventDefault()
    event.returnValue = 'Please disconnect server first!'
    return event
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.connectionForm]
  }

  private markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  private openAlert (check: boolean, message: string): void {
    this.alert = new Alert(
      { type: (check ? AlertType.INFO : AlertType.DANGER), message }
    )
    this.alert.present()
  }

  public initializeLogs () {
    if (this.logSubscription) {
      this.logSubscription.unsubscribe()
    }
    const tail = this.logsForm.get(`tail`).value
    const logFilter = this.logsForm.get(`filter`).value

    this.logSubscription = this._echarger
      .virtualEcGetContinuosLogs(this.socket, logFilter, tail)
      .pipe(map(response => {
        if (response.invalid) {
          this.openAlert(false,
            `${this._translate.instant(`ALERT.MESSAGE.INVALID`)} ${response.message}`
          )
        }
        return response.data
      }),
      takeWhile(() => this.connected, true)
      ).subscribe(log => {
        this.logs = log
      })

    this.subscriptions.push(this.logSubscription)
  }

  public async connectToServer (): Promise<void> {
    if (this.connectionForm.invalid) {
      this.openAlert(false, `${this._translate.instant(`ALERT.MESSAGE.VC.DIFFERENT_ECHARGER`)}`)
      return
    }

    try {
      this.connectionForm.markAsUntouched()
      const echargerCode = this.connectionForm.get(`echargerCode`).value.toUpperCase()
      const selectedZoneId = this.connectionForm.get(`zone`).value
      const prechargeLimit = this.connectionForm.get(`prechargeLimit`).value

      const response = await this._echarger
        .virtualEcConnect(
          selectedZoneId, echargerCode, prechargeLimit
        )
        .toPromise()

      if (response.invalid) {
        this.openAlert(false,
          `${this._translate.instant(`${response.message}`)}.
          ${this._translate.instant(`ALERT.MESSAGE.VC.DIFFERENT_ECHARGER`)}`
        )
        return
      }

      this.echargerId = response.data.echarger
      this.socket = response.data.socket

      this.openAlert(true,
        `${this._translate.instant(`ALERT.MESSAGE.VC.CONNECTED`)} (Socket: ${this.socket})`
      )

      // -- successfully connected
      this.connected = true
      this.connectionForm.disable()
      this.request44RegistrationForm.enable()
      this.request42PrechargeForm.enable()
      this.response41ChargeInfoForm.enable()

      // -- fill echarger code in filter
      this.logsForm.patchValue({ filter: `[${echargerCode.toUpperCase()}]` })

      // -- show special name warning
      this.showEchargerCodeWarning = echargerCode.startsWith(`H`) || echargerCode.startsWith(`SC`)

      // -- setup continous log loading
      this.initializeLogs()

      // -- setup transaction info view
      this.initializeTransView()
    } catch (error) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.SYSTEM_ERROR`)} ${error.statusText}!
        ${this._translate.instant(`ALERT.MESSAGE.VC.DIFFERENT_ECHARGER`)}`
      )
    }
  }

  public async disconnectServer (): Promise<void> {
    try {
      const response = await this._echarger
        .virtualEcDisconnect(this.socket).toPromise()

      if (response.invalid) {
        this.openAlert(false,
          `${this._translate.instant(`ALERT.MESSAGE.INVALID`)} ${response.message}`
        )
        return
      }

      this.openAlert(true,
        `${this._translate.instant(`ALERT.MESSAGE.VC.DISCONNECTED`)}`
      )

      this.connected = false
      this.connectionForm.enable()
      this.request44RegistrationForm.disable()
      this.request42PrechargeForm.disable()
      this.response41ChargeInfoForm.disable()

      // -- the page may savefely be closed by the browser if just disconnected
      this.connectionForm.markAsPristine()

      this.showEchargerCodeWarning = false

      this.logSubscription.unsubscribe()

      this.logsForm.patchValue({ filter: `` })

      this.transactions = undefined
      this.transactionsCache = new Array<Transaction>(4)
      this.transactionsView = undefined
    } catch (error) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.SYSTEM_ERROR`)} ${error.statusText}`
      )
    }
  }

  public async connectOrDisconnectServer (): Promise<void> {
    if (this.connectionForm.invalid) {
      return this.markFormGroupTouched(this.connectionForm)
    }
    if (!this.connected) {
      await this.connectToServer()
    } else {
      await this.disconnectServer()
    }
  }

  public async requestRegistration44 (): Promise<void> {
    const protocolVersion = this.request44RegistrationForm.get(`protocol`).value
    try {
      const response = await this._echarger.virtualEcRequestRegister(
        this.socket,
        protocolVersion
      ).toPromise()

      if (response.invalid) {
        this.openAlert(false,
          `${this._translate.instant(`ALERT.MESSAGE.INVALID`)} ${response.message} ${response.data}`
        )
        return
      }

      this.openAlert(true,
        `${this._translate.instant(`ALERT.MESSAGE.VC.REQUEST.REGISTERED`)} ${response.message}`
      )
    } catch (error) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.SYSTEM_ERROR`)} ${error.statusText}`
      )
    }
  }

  public async requestPrecharge42 (): Promise<void> {
    if (this.request42PrechargeForm.invalid) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.INVALID_INPUT`)} (42)`
      )
      return
    }

    try {
      const response = await this._echarger.virtualEcRequestPrecharge(
        this.socket,
        this.request42PrechargeForm.value
      ).toPromise()

      if (response.invalid) {
        this.openAlert(false,
          `${this._translate.instant(`ALERT.MESSAGE.INVALID`)} ${response.message}`
        )
        return
      }

      this.openAlert(true,
        `${this._translate.instant(`ALERT.MESSAGE.VC.REQUEST.PRECHARGE`)} ${response.message}`
      )

      await this.initializeTransView()
    } catch (error) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.SYSTEM_ERROR`)} ${error.statusText}`
      )
    }
  }

  public async responseChargeInfo41 (): Promise<void> {
    if (this.response41ChargeInfoForm.invalid) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.INVALID_INPUT`)} (41)`
      )
      return
    }

    try {
      const response = await this._echarger.virtualEcResponseChargeInfo(
        this.socket,
        this.response41ChargeInfoForm.value
      ).toPromise()

      if (response.invalid) {
        this.openAlert(false,
          `${this._translate.instant(`ALERT.MESSAGE.INVALID`)} ${response.message}`
        )
        return
      }

      this.openAlert(true,
        `${this._translate.instant(`ALERT.MESSAGE.VC.RESPONSE.CHARGEINFO`)} ${response.message}`
      )

      await this.initializeTransView()
    } catch (error) {
      this.openAlert(false,
        `${this._translate.instant(`ALERT.MESSAGE.SYSTEM_ERROR`)} ${error.statusText}`
      )
    }
  }

  public transactionsFound (gun?: number): boolean {
    return gun !== undefined
      ? !!(this.transactions && this.transactions
        .filter((transaction) => transaction.gun === gun).length)
      : !!(this.transactions && this.transactions
        .filter((transaction) => transaction).length)
  }

  public transactionsViewsFound (gun?: number): boolean {
    return gun !== undefined
      ? !!(this.transactionsView && this.transactionsView
        .filter((transaction) => transaction.gun === gun).length)
      : !!(this.transactionsView && this.transactionsView
        .filter((transaction) => transaction).length)
  }

  public async initializeTransView () {
    await new Promise(resolve => setTimeout(resolve, 2000))

    const responseConnection = await this._transaction
      .getEchargersConnection(this.echargerId).toPromise()

    if (responseConnection.invalid) {
      if (responseConnection.message === 'ERROR.NO_CONNECTION_FOUND') {
        responseConnection.data.transactions = []
      } else {
        return this.openAlert(false,
          `${this._translate.instant('ALERT.MESSAGE.INVALID')} ` +
          `${this._translate.instant(responseConnection.message)}`
        )
      }
    }

    const closedIndex = await this.firstTransactions.findIndex(f => f.startsWith('!'))

    if (
      closedIndex !== -1 &&
      await this.transactions.findIndex(t =>
        t._id === this.firstTransactions[closedIndex].slice(1)
      ) !== -1
    ) {
      for (let i = 0; i < 8; i++) {
        this.firstTransactions[i] = i === closedIndex
          ? this.firstTransactions[i]
          : ''
      }
    } else {
      this.firstTransactions = ['', '', '', '']
    }

    this.transactions = responseConnection.data.transactions

    // store transaction with gunNr in view buffer
    this.transactions.forEach( trans => {
      if (trans) {
        [0, 1, 2, 3].forEach(i => {
          if (trans.gun === i + 1) {
            this.transactionsCache[i] = trans
          }
        })
      }
    })

    this.transactionsView = this.transactionsCache.filter(t => t !== undefined)

    this.transactionsView.forEach(tv => {
      if (this.transactions.filter(t => t.gun === tv.gun).length === 0) {
        if (tv.data.length > 0) {
          tv.data[tv.data.length - 1].chargeStatus = `WasFinished`
          tv.active = false
        }
      }
    })
  }

  public calculateKW (current: number, voltage: number): number {
    return Math.round(current * voltage / 100) / 10
  }

  public noAlarms (alarms: any[]) {
    return alarms.every(a => a === 'No alarm')
  }

  public notAutoOpen (index: number, id: string, closed: boolean) {
    const tr = this.firstTransactions[index]
    if (tr === id || tr.slice(1) === id) {
      if (closed) {
        this.firstTransactions[index] = '!' + id
      } else {
        // remove ! if tab is openend again, doesnt need an extra icon since
        // only one can be active
        if (tr.startsWith('!')) {
          this.firstTransactions[index] = tr.slice(1)
        }
      }
    }
  }

  public copyMessage (message: string): void {
    const cb = 'clipboard'
    const clipboard = window.navigator[cb]

    clipboard.writeText(message)
  }

  public randomEvccid (): void {
    const evccid = 'x'
      .repeat(12)
      .replace(/x/g, () => Math.floor(Math.random() * 16).toString(16))
      .toUpperCase()

    this.request42PrechargeForm.patchValue({ evccid })
    this.response41ChargeInfoForm.patchValue({ evccid })
  }
}
